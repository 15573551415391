import { Hero, Cta, Instructions, Contact } from "@/components";

// @ is an alias to /src
export default {
	name: "Home",
	components: {
		Hero,
		Cta,
		Instructions,
		Contact,
	},
	data: () => ({
		cta: {
			title: "Maak een account \nvoor een GRATIS \noefenexamen",
			imgPath: require("@/assets/images/mobile-platform.png"),
			description:
				"Probeer ons platform gratis uit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae lacus eget turpis maximus ultricies.",
			buttonText: "MAAK EEN ACCOUNT",
			rowReverse: true,
		},
	}),
};
