<template>
	<Hero />
	<Cta
		:img-path="cta.imgPath"
		:title="cta.title"
		:description="cta.description"
		:row-reverse="cta.rowReverse"
		:button-text="cta.buttonText"
	/>
	<Instructions id="instructions" />
	<Contact id="contact" />
</template>

<script src="./Home.controller.js"></script>
<style lang="scss" src="./Home.styles.scss"></style>
